import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { Location } from '@angular/common';

import { Title } from '../Models/title.model';
import { DtLanguages } from '../shared/dtLanguages';
import { AuthService } from '../services/auth-service';
import { ReorderService } from '../services/reorder.service';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Router } from '@angular/router';
import { GA } from '../shared/gAnalytics';
import { Sweetalert } from '../shared/sweetAlert';
import * as moment from 'moment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-reorder',
  templateUrl: './reorder.component.html',
  styleUrls: ['./reorder.component.css']
})
export class ReorderComponent implements OnInit, OnDestroy, AfterViewInit {

  // datatable initialisations
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective)
  dtElement: DataTableDirective;
  showModalSpinner: Subject<boolean> = new Subject<boolean>();

  // constructor
  constructor(private reorderRequest: ReorderService,
    private authService: AuthService,
    private reorderService: ReorderService,
    private ngbModalService: NgbModal,
    private ngbModalConfig: NgbModalConfig,
    private dtlanguages: DtLanguages,
    private route: ActivatedRoute,
    private router: Router,
    private ga: GA,
    private location: Location,
    private sweet: Sweetalert) {
    ngbModalConfig.backdrop = 'static',
      ngbModalConfig.keyboard = false;
  }

  // declarations
  private titlesSubs: Subscription;
  private saveSubs: Subscription;
  titles: Title[];
  showTableSpinner = true;
  searchString: string;
  typeAction = 'reorder';
  titleCodif: string;
  @ViewChild('content') contentRef: ElementRef;

  ngOnInit() {
    // datatable options
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      processing: true,
      searching: true,
      responsive: true,
      lengthChange: true,
      order: [1, 'desc'],
      language: this.dtlanguages.frLanguage()
    };

    this.titlesSubs = this.reorderRequest.getReorderRequestsUpdateListner()
      .subscribe((titleResult: Title[]) => {
        this.titles = titleResult;
        this.showTableSpinner = false;
        this.rerender();
        this.dtTrigger.next();
      });

    this.saveSubs = this.reorderService.saveReorderRequestsUpdateListner()
      .subscribe(result => {
        this.showModalSpinner.next(false);
        if (!result) {
          this.sweet.error('Attention !', 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.');
          return;
        }
        if (result.status) {
          if (this.titleCodif != null) {
            this.location.back();
            return;
          }
          this.getReorders();
          this.closeModal(false);
          this.ga.createEvent('click', 'Demandes', 'Création Nouvelle Demande de réassort');

          if (result.serviceQuantity) {
            const msg = result.message || 'Votre demande de réassort est enregistrée, souhaitez-vous installer le titre ?';
            // tslint:disable-next-line:max-line-length
            this.sweet.confirmDialog('Félicitation !', msg, (error: Error, answer: any): void => {

              if (answer) {
                // redirect to service
                const url = `services/titles/${result.codif}`;
                const queryParam = { q: result.serviceQuantity, m: result.motif};
                this.router.navigate([url], { queryParams: queryParam });
              }
            });
          }
        } else {
          const msg = result.message || 'Une erreur est survenue lors de la validation de votre demande, veuillez contacter le support.';
          this.sweet.error('Attention !', msg);
        }
      });

    this.showTableSpinner = true;
    this.getReorders();
  }

  // rerender the datatable
  rerender() {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
    });
  }

  ngAfterViewInit() {
    if (this.route.snapshot.paramMap.get('titleCodif') != null) {
      this.titleCodif = this.route.snapshot.paramMap.get('titleCodif');

      setTimeout(() => {
        this.openModal(this.contentRef);
      });
    }
    this.dtTrigger.next();
  }

  getReorders() {
    this.reorderRequest.getReorderRequests();
  }

  source(title: Title): string {

    if (!title
      || !title.tracking) {
      return '';
    }

    let fa = 'fa-cogs', requestor = 'FM';
    if (title.tracking.requestor === 'store') {
      fa = 'fa-user';
      requestor = 'diffuseur';
    } else if (title.tracking.requestor === 'warehouse') {
      fa = 'fa-sitemap';
      requestor = 'dépôt';
    } else if (title.tracking.requestor === 'headoffice') {
      fa = 'fa-home';
      requestor = 'siège';
    }
    return `<i class="fa ${fa}" data-toggle="tooltip" data-placement="top" title="${requestor} / ${title.tracking.source}"></i>`;
  }

  reorderTitle(titleCodif: string) {
    if (!titleCodif || !titleCodif.length) {
      return;
    }
    this.router.navigate(['/reorder/' + titleCodif]);
  }

  // on post new reorder
  onSaveRequest($event) {
    if (!$event) {
      this.showModalSpinner.next(false);
      return;
    }

    const initialQuantity = $event[0].provided;
    const eventQuantity = parseInt($event[0].quantity, 0) || 0;
    let checkQuantity = initialQuantity > 0;
    if (checkQuantity) {
      checkQuantity = eventQuantity > 2 * initialQuantity && eventQuantity > 10;
    } else {
      checkQuantity = eventQuantity > 10;
    }

    // tslint:disable-next-line:max-line-length
    const reorderMessage = 'Vous êtes sur le point de commander ' + eventQuantity + ' exemplaires de “' + $event[0].title + '” n° ' + $event[0].numeroParution + ', souhaitez-vous continuer ?';
    let message = checkQuantity ? 'La quantité commandée est importante. Souhaitez-vous continuer ?' : reorderMessage;

    const existing = this.titles.find(t => t.codif === $event[0].codif
                          && !t.delivred
                          && t.numeroParution === $event[0].numeroParution);

    if (existing && existing.quantity) {
      // tslint:disable-next-line:max-line-length
      message = `Vous avez déjà une commande en cours de ${existing.quantity} ex pour la codif ${existing.codif} N° ${existing.numeroParution}<br/>Souhaitez vous valider une nouvelle commande ?`;
    }

    this.sweet.confirmDialog('Attention !', message, (error: Error, result: any): void => {

      if (result) {
        this.reorderService.addNewReorder($event);
      } else {
        this.showModalSpinner.next(false);
      }
    });
  }

  // on open modal and logic
  openModal(content) {
    this.ngbModalService.open(content, { size: 'lg' });
    this.ga.createEvent('view', 'Demandes', 'Création Nouvelle Demande de réassort');

  }

  closeModal(sendrRacking = true) {
    if (sendrRacking) {
      this.ga.createEvent('cancel', 'Demandes', 'Création Nouvelle Demande de réassort');
    }
    this.ngbModalService.dismissAll();
  }

  ngOnDestroy() {
    this.closeModal(false);
    if (this.dtTrigger) {
      this.dtTrigger.unsubscribe();
    }
    if (this.titlesSubs) {
      this.titlesSubs.unsubscribe();
    }
    if (this.saveSubs) {
      this.saveSubs.unsubscribe();
    }
    if (this.showModalSpinner) {
      this.showModalSpinner.unsubscribe();
    }
  }

}
